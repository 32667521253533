import React, {useEffect, useRef, useState} from "react";
import {Button, Col, Form, Row, message} from "antd";

import IntlMessages from "util/IntlMessages";
import AuthLayout from "./AuthLayout";
import {createProfileApplication} from "../appRedux/model/profileApplication";
import {ProFormDatePicker, ProFormSwitch, ProFormText, ProFormTextArea, StepsForm} from "@ant-design/pro-form";
import {useHistory, useLocation} from "react-router-dom";
import ProDatePicker from "../components/ProDatePicker";

const RegisterProfile = (props) => {

  const [statusSuccess, setStatusSuccess] = useState({status: null})
  const [isReligious, setIsReligious] = useState(true)
  const [isInterviewer, setIsInterviewer] = useState(false);
  const history = useHistory();

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userType = searchParams.get('userType');
    setIsInterviewer(userType === 'interviewer'); 
  }, [location.search]);

  const onBack = () =>{
    history.push('/signin');
  }
  const formRef = useRef();
  const formMapRef = useRef();

  const handleFinish = async (values) => {
    try {
      await createProfileApplication(values);
      setStatusSuccess({ status: true });
    } catch (error) {
      console.error(error);
      setStatusSuccess({ status: false });
      message.error('Đăng ký không thành công!')
    }
  };

  return (
    <AuthLayout title={'Đăng ký hồ sơ'} statusSuccess={statusSuccess} formInput={
      <div className={'container-register'}>
        <StepsForm
          formMapRef={formMapRef}
          formRef={formRef}
          onFinish={handleFinish}
          stepsRender={() => null}
          containerStyle={{marginTop: '-20px'}}

          submitter={{
            render: props => {
              const { step, onSubmit, onPre } = props;

              const isFirstStep = step === 0;

              if (!formMapRef.current) return null;

              const [basicInfoFormRef] = formMapRef.current;

              const isReligious = basicInfoFormRef.current.getFieldValue('religious');

              if (!formRef.current) return null;

              if (isFirstStep) {
                return [
                  <Button key={'login'} onClick={() => onBack()}>
                    Đăng nhập
                  </Button>,
                   !isReligious && isInterviewer ? (
                    <Button key={'register'} type="primary" onClick={() => onSubmit()}>
                      Đăng ký
                    </Button>
                  ) : (
                    <Button key={'next'} type="primary" onClick={() => onSubmit()}>
                      Tiếp theo
                    </Button>

                  ),
                 
                ];
              }
          
              if (step === 1 && isReligious) {
                return [
                  <Button key="step1" onClick={() => onPre?.()}>
                    Quay lại
                  </Button>,
                    isInterviewer ? (
                      <Button key={'register'} type="primary" onClick={() => onSubmit()}>
                        Đăng ký
                      </Button>
                    ) : (
                      <Button key={'next'} type="primary" onClick={() => onSubmit()}>
                        Tiếp theo
                      </Button>
  
                    ),
                ];
              }
          
              return [
                <Button key="step2" onClick={() => onPre?.()}>
                  Quay lại
                </Button>,
                <Button
                  type="primary"
                  onClick={() => onSubmit?.()}
                >
                  Đăng kí
                </Button>,
              ];
            }
          }}

        >
          <StepsForm.StepForm
            name="step1"
            onFinish={async () => {
              const [basicInfoFormRef, religiousFormRef] = formMapRef.current
              const isReligious = basicInfoFormRef.current.getFieldValue('religious')
              if (!isReligious) {
                religiousFormRef.current.resetFields()
              }
              return true
            }}
          >

            <Row gutter={16}>
              <Col span={12}>
                <ProFormText name="lastName" placeholder="Họ"
                  rules={[{required: true, message: <IntlMessages id="message.lastName"/>}]}
                />
              </Col>
              <Col span={12}>
                <ProFormText name="firstName" placeholder="Tên"
                  rules={[{required: true, message: <IntlMessages id="message.firstName"/>}]}
                />
              </Col>
            </Row>

            <Row gutter={16}>
              <Col span={12}>
                 <ProDatePicker name="birthday"  placeholder='Ngày sinh'
                             rules={[{required: true, message: IntlMessages({ id: "message.birthday" })}]}/>
              </Col>
              <Col span={12}>
                <ProFormText name="mobile" placeholder="Số điện thoại"
                  rules={[{required: true, message: <IntlMessages id="message.mobile"/>,}]}
                />

              </Col>
            </Row>


            <ProFormText name="email" placeholder="Email"
              rules={[{required: true, type: 'email', message: <IntlMessages id="message.email"/>,}]}
            />
            <ProFormTextArea name="introduction" placeholder={'Giới thiệu bản thân'}
              rules={[{required: !isInterviewer, message: <IntlMessages id="message.introduction"/>}]}
            />


            <ProFormSwitch
              label={"Bạn có theo đạo không?"}
              checkedChildren={'Có'}
              unCheckedChildren={"Không"}
              value={isReligious}
              initialValue={true}
              name={'religious'}
              onChange={() => setIsReligious(!isReligious)}
            />
          </StepsForm.StepForm>

          {isReligious && <StepsForm.StepForm
            name="step2"
            onFinish={async () => {
              return true
            }}
          >
            <ProFormText name="holyName" placeholder="Tên thánh"/>
            <ProFormText name="parish" placeholder="Giáo xứ"/>
            <ProFormText name="diocese" placeholder="Giáo phận"/>

          </StepsForm.StepForm>}

          {!isInterviewer && 
           <StepsForm.StepForm
           name="step3"
           onFinish={async () => {
             return true
           }}
         >
           <Row gutter={16}>
             <Col span={12}>
               <ProFormText name="college" placeholder="Trường học" 
                 rules={[{required: true, message: <IntlMessages id="message.college"/>}]}
               />
             </Col>
             <Col span={12}>
               <ProFormText name="program" placeholder="Chuyên ngành"
                 rules={[{required: true, message: <IntlMessages id="message.program"/>}]}
               />
             </Col>

               <Col span={12}>
                 <ProDatePicker picker="month" format={"MM/YYYY"} name="joiningDate"  placeholder={'Ngày nhập học'} 
                     rules={[{required: true, message: <IntlMessages id="message.joiningDate"/>}]}
                   />
                  
               </Col>
               <Col span={12}>
                 <ProDatePicker picker="month" format={"MM/YYYY"} placeholder={'Ngày tốt nghiệp'} name="anticipatedGraduationDate" />
               </Col>

           </Row>

         </StepsForm.StepForm>}
         

        </StepsForm></div>
    }
        requestSuccessMessage={<IntlMessages id={"app.userAuth.signUpSuccessMessage"}/>}
        requestErrorMessage={<IntlMessages id={"app.userAuth.signUpErrorMessage"}/>}
    />
  );
};

export default RegisterProfile;

