import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Avatar, Col, Popover, Row, Space, Upload} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import {useHistory} from "react-router-dom";
import {deleteFile, getFileUploadUrl, uploadFile} from "../../appRedux/model/file";
import {updateProfile} from "../../appRedux/model/profile";
import {fetchSignInUserProfile} from "../../appRedux/actions";
import ImgCrop from "antd-img-crop";
import ChangePassword from "../ChangePassword";
import IntlMessages from "../../util/IntlMessages";
import {checkImageType} from "../../util/Validation";

// export const convertToUri = file => {
//   return new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     reader.onloadend = () => resolve(reader.result);
//     reader.onerror = () => reject(reader.error);
//     reader.readAsDataURL(file);
//   })
// }
const UserProfile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const {authUser, initURL, userProfile} = useSelector(({auth}) => auth);
  const [isChangePwd, setIsChangePwd] = useState(false)
  const [isPopover, setIsPopover] = useState(false)

  function fetchUserProfile() {
    dispatch(fetchSignInUserProfile(authUser.profileId))
  }

  useEffect(() => {
    fetchUserProfile();
  }, [authUser]);

  const {lastName, firstName, avatarUrl} = userProfile || {};
  const displayText = [lastName, firstName].filter(s => !!s).join(" ")
  const removeOldAvatar = () => {
    return avatarUrl ? deleteFile(avatarUrl) : true;
  }
  const updateProfileWithNewAvatar = newUrl => updateProfile(authUser.profileId, {avatarUrl: newUrl})
  const [avatarUri, setAvatarUri] = useState();
  useEffect(() => {
    avatarUrl && setAvatarUri(getFileUploadUrl(avatarUrl))
  }, [avatarUrl]);

  const closeModal = () => {
    setIsChangePwd(false)
  }
  const closePopover = () => {
    setIsPopover(false)
  }
  const handleBeforeUpload = (file) => {
    return checkImageType(file);
  };
  const userMenuOptions = (
    <ul className="gx-user-popover">
      <li className={"gx-pointer"} onClick={() => {
        history.push(`/profile/${authUser.profileId}`)
        setIsPopover(false)
      }}><IntlMessages id={"header.user.profile"}/></li>
      <li className={"gx-pointer"} onClick={() => {
        setIsPopover(false)
        setIsChangePwd(true)
      }}><IntlMessages id={"header.user.changePwd"}/></li>
      <li className={"gx-pointer"}>

        <ImgCrop rotate shape='round' beforeCrop={file => {
          console.log(file)
          return file
        }}>
          <Upload fileList={null}
                  beforeUpload={handleBeforeUpload}
                  customRequest={async ({file, onSuccess, onError}) => {
                    uploadFile(file, {belongTo: authUser.profileId, uploadType: 'avatar'})
                      .then(updateProfileWithNewAvatar)
                      .then(removeOldAvatar)
                      .then(fetchUserProfile)
                      .then(onSuccess)
                      .catch(onError);
                  }}>
            <IntlMessages id={"header.user.changeAvatar"}/>
          </Upload></ImgCrop>
      </li>
      <li className={"gx-pointer"} onClick={() => dispatch(userSignOut())}><IntlMessages id={"header.user.logout"}/>
      </li>
    </ul>
  );
  const handleOpenChange = (newOpen) => {
    setIsPopover(newOpen);
  };
  return (
    <>
      <ChangePassword {...{isChangePwd, closeModal}}/>

      <div className="gx-flex-row gx-align-items-center gx-avatar-row ">
        <Popover placement="bottomRight" content={userMenuOptions} trigger="click" open={isPopover}
                 onOpenChange={handleOpenChange}>

          <Row align={"middle"} gutter={1} justify={"space-between"}>
            <Col lg={8} md={6} sm={8} xs={7}>
              <Avatar src={avatarUri} className="gx-size-40 gx-pointer gx-mr-2 " alt="" crossOrigin="anonymous" style={avatarUri?null:{backgroundColor:'#87d068'}}>
                {avatarUri?null:firstName?.substring(0, 1)}
              </Avatar>

            </Col>
            {/*{props.showName && <Col lg={14} md={{span:15, offset:3}} sm={{span:15, offset:2}} xs={{span:15,offset:2}}>*/}
            {props.showName && <Col lg={14} md={{span:15, offset:3}} sm={{span:17}} xs={{span:15,offset:2}}>
              <Space className="gx-avatar-name">
                <span>{displayText}</span>
                <i className="icon icon-chevron-down gx-fs-xxs "/>
              </Space>
            </Col>}
          </Row>
        </Popover>
      </div>
    </>

  )
};

export default UserProfile;
